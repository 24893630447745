<template>
  <div class="container row middle-xs center-xs">
    <div class="window col-xs-10">
      <div class="row header center-xs">Pixel Install Guide</div>
      <div class="row guide top-xs start-xs">
        The Xandr pixel is a small piece of JavaScript code that you paste into
        your pages.

        <!-- To implement the pixel on your website: -->

        <div class="copy">
          The Conversion and Retargeting pixels are installed in similar ways
          with a small difference. <b>Retargeting</b> pixels should be installed
          on <b>every</b> page of your site, while <b>conversion</b> pixels
          should be placed only on confirmation pages or during form submission
          (checkout).

          <h3>Retargeting Pixel</h3>

          <br />
          Copy the JavaScript below and paste it as close to the closing
          <code class="html">&lt;/head&gt;</code> tag as possible on
          <b>every</b> page of your website, replacing <b>XXXX</b> with your
          pixel ID:

          <div class="code-wrapper">
            <transition name="flip" mode="out-in" :duration="0.5">
              <font-awesome-icon
                v-if="!copyingSegment"
                @click="onClickCopySegment"
                class="clip"
                :icon="['fal', 'copy']"
              />
            </transition>
            <transition name="flip" mode="out-in" :duration="0.5">
              <font-awesome-icon
                v-if="copyingSegment"
                class="clip"
                :icon="['fal', 'check']"
              />
            </transition>
            <pre><code class="language-html">{{ sampleSegmentPixelCode }}</code></pre>
            <br />
          </div>

          <h3>Conversion pixel</h3>

          <br />
          Copy the JavaScript below and paste it as close to the closing
          <code class="html">&lt;/head&gt;</code> tag as possible on your
          website's order confirmation page, replacing <b>XXXX</b> with your
          pixel ID:

          <div class="code-wrapper">
            <transition name="flip" mode="out-in" :duration="0.5">
              <font-awesome-icon
                v-if="!copyingConversion"
                @click="onClickCopyConversion"
                class="clip"
                :icon="['fal', 'copy']"
              />
            </transition>
            <transition name="flip" mode="out-in" :duration="0.5">
              <font-awesome-icon
                v-if="copyingConversion"
                class="clip"
                :icon="['fal', 'check']"
              />
            </transition>

            <pre><code class="language-html">{{ samplePixelCode }}</code></pre>
            <br />
          </div>

          <p>
            Alternatively, the pixel can be fired <i>dynamically</i> via code,
            using a method similar to the following:
          </p>

          <div class="code-wrapper">
            <transition name="flip" mode="out-in" :duration="0.5">
              <font-awesome-icon
                v-if="!copyingConversionCode"
                @click="onClickCopyConversionCode"
                class="clip"
                :icon="['fal', 'copy']"
              />
            </transition>
            <transition name="flip" mode="out-in" :duration="0.5">
              <font-awesome-icon
                v-if="copyingConversionCode"
                class="clip"
                :icon="['fal', 'check']"
              />
            </transition>

            <pre><code>{{ samplePixelCodeJS }}</code></pre>
            <br />
          </div>

          <br />Note: you can also copy the code directly from the pixel
          generator which already includes the correct ID.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Prism from "prismjs";
import "prismjs/themes/prism.css";

export default {
  name: "PixelDocs",
  data() {
    return {
      samplePixelCode: `<!-- Conversion pixel XXXX -->
<script src='https://secure.adnxs.com/px?id=XXXX&t=1' />
<!-- End of Conversion pixel -->`,

      samplePixelCodeJS:
        `<!-- Conversion pixel XXXX -->
<script>
function dropConversionPixel() {
  var script = document.createElement("script");
  script.src = 'https://secure.adnxs.com/px?id=XXXX&t=1';
  document.head.appendChild(script);
}
// Somewhere in your codebase..
function onClickMyButton() {
  // do something like make an API call, etc
  dropConversionPixel();
}
</` +
        `script>
<!-- End of Conversion pixel -->`,

      sampleSegmentPixelCode: `<!-- Retargeting pixel XXXX -->
<script src='https://secure.adnxs.com/seg?add=XXXX&t=1' />
<!-- End of Retargeting pixel -->`,

      copyingConversion: false,
      copyingConversionCode: false,
      copyingSegment: false,
    };
  },
  mounted() {
    Prism.highlightAll();
  },
  methods: {
    onClickCopyConversion() {
      window.navigator.clipboard.writeText(this.samplePixelCode);
      this.copyingConversion = true;
      setTimeout(() => {
        this.copyingConversion = false;
      }, 2000);
    },
    onClickCopyConversionCode() {
      window.navigator.clipboard.writeText(this.samplePixelCodeJS);
      this.copyingConversionCode = true;
      setTimeout(() => {
        this.copyingConversionCode = false;
      }, 2000);
    },
    onClickCopySegment() {
      window.navigator.clipboard.writeText(this.sampleSegmentPixelCode);
      this.copyingSegment = true;
      setTimeout(() => {
        this.copyingSegment = false;
      }, 2000);
    },
  },
};
</script>
<style lang="scss">
// flip transition for splash/login screen
.flip-enter-active,
.flip-leave-active {
  transition: all 0.5s ease-in-out;
}

.flip-enter,
.flip-leave-to {
  filter: FlipH;
  opacity: 0;
  transform: scaleX(-1);
}

.window {
  z-index: 1;
  width: 100%;
  max-width: 100%;
  height: unset !important;
  .guide {
    height: 100%;
    padding: 20px;
    background-color: white;
    .copy {
      margin-top: 15px;

      @media (min-width: 1200px) {
        width: 75%;
      }
      p,
      h3 {
        margin-top: 15px;
      }
    }
    .code-wrapper {
      padding-right: 15px;
      padding-left: 15px;
      margin-top: 5px;
      margin-bottom: 5px;
      background: #f5f2f0;

      @media (min-width: 1200px) {
        width: 85%;
      }
      .clip {
        position: relative;
        top: 10px;
        right: -5px;
        float: right;
        cursor: pointer;
        transition: transform 0.2s;
        &:hover {
          transform: scale(1.3);
        }
      }
    }

    pre {
      width: 100%;
      padding: 0;
      margin: 0;
      border-radius: 5px;
    }
  }
}
.html {
  padding: 4px;
  font-family: monospace;
  font-weight: bold;
  background: $grey_11;
  border-radius: 10px;
}
</style>
