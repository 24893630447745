<template>
  <div></div>
</template>
<script>
export default {
  created() {
    localStorage.removeItem("token");
    this.$store
      .dispatch("authentication/logout")
      .then(() => {
        document.body.classList.remove("bg-image");
      })
      .then(() => {
        return this.$router.push("/login");
      });
  }
};
</script>
