<template>
  <div class="">
    <iframe :src="url" frameborder="0" id="frame"></iframe>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
export default {
  async mounted() {
    // let iframe = document.getElementById("frame");
    await this.advisrAuthentication();
  },
  computed: { ...mapState({ url: state => state.advisr_url }) },
  methods: {
    ...mapActions({ advisrAuthentication: "advisrAuthentication" })
  }
};
</script>
<style lang="scss" scoped>
iframe {
  width: 100%;
  min-height: 1055px;
  max-height: 2160px;
}
</style>
