import Vue from "vue";
import VueRouter from "vue-router";
import VueCookies from "vue-cookies";
import AsyncComputed from "vue-async-computed";
import Logout from "../pages/Logout";
import Home from "../pages/Home";
import Reporting from "../pages/Reporting";
import PlanCreate from "../pages/PlanCreate";
import Login from "../pages/Login";
import ForgotPassword from "../pages/ForgotPassword.vue";
import ResetPassword from "../pages/ResetPassword.vue";
import PixelDocs from "../pages/PixelDocs";
import axios from "axios";
import store from "../store";
const token = localStorage.getItem("token");
import config from "../config";
Vue.use(VueRouter);
Vue.use(AsyncComputed, {
  useRawError: true,
  // Custom error handler to properly display the error and exception stack in
  // the console. The default handler prints the stack array directly which
  // isn't properly formatted.
  errorHandler(err /* , vm, stack */) {
    console.error("Caught asyncComputed error\n", err);
  },
});

const routes = [
  { path: "/login", name: "login", component: Login },
  { path: "/logout", name: "logout", component: Logout },
  { path: "/forgot", name: "forgot", component: ForgotPassword },
  {
    path: "/password/reset/:email/:token",
    name: "reset",
    component: ResetPassword,
  },
  {
    path: "/help/pixel",
    name: "pixeldocs",
    component: PixelDocs,
  },
  {
    path: "/",
    name: "home",
    component: Home,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/reporting",
    name: "reporting",
    component: Reporting,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/plan-create",
    name: "PlanCreate",
    component: PlanCreate,
    meta: {
      requiresAuth: true,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

function renewJWT(cookieToken, next) {
  axios({
    method: "post",
    url: `${config.API_URL}/new_token`,
    data: { token: cookieToken },
    headers: { Authorization: `Bearer ${cookieToken}` },
  })
    .then((response) => {
      console.log("got response", response);
      localStorage.setItem("token", response.data.token);
      store.dispatch("authentication/decodeToken", token);
      next();
    })
    .catch(() => {
      next("/login");
    });
}

router.beforeEach((to, from, next) => {
  if (!to.matched.some((record) => record.meta.requiresAuth)) {
    next();
  } else {
    let isAuthenticated = store.getters["authentication/isAuthenticated"];

    //if the user is not authenticated but a token was fetched from localstorage
    //save the token to the store and get the value of isAuthenticated again
    if (!isAuthenticated) {
      if (token) {
        store.dispatch("authentication/decodeToken", token);
        isAuthenticated = store.getters["authentication/isAuthenticated"];
        if (isAuthenticated) {
          store.dispatch("authentication/setAdreadyAccessToken", token);
        }
      } else {
        // see if we have it in a cookie and convert to local
        const cookieToken = VueCookies.get("token_plus");
        if (cookieToken) {
          renewJWT(cookieToken, next);
          return;
        }
      }
    }
    if (!isAuthenticated) {
      next("/login");
      return;
    }

    next();
  }
});

export default router;
