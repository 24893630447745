<template>
  <div class="container row middle-xs center-xs">
    <div class="window col-xs-10">
      <div class="row header center-xs">
        Get Started
      </div>
      <div class="row selection center-xs middle-xs">
        <a href="/plan-create" class="plan">
          <div class="row center-xs middle-xs">
            <div class="icon-wrap">
              <font-awesome-icon
                :icon="['far', 'check']"
                class="check"
              ></font-awesome-icon>
              <font-awesome-icon
                :icon="['fal', 'sliders-h']"
                class="icon"
              ></font-awesome-icon>
            </div>
          </div>
          <div class="row center-xs">
            <p>Create a Plan</p>
          </div>
        </a>
        <div class="divider"></div>
        <a href="/reporting" class="reporting">
          <div class="row center-xs middle-xs">
            <div class="icon-wrap">
              <font-awesome-icon
                :icon="['far', 'check']"
                class="check"
              ></font-awesome-icon>
              <font-awesome-icon
                :icon="['fal', 'analytics']"
                class="icon"
              ></font-awesome-icon>
            </div>
          </div>
          <div class="row center-xs">
            <p>Reporting</p>
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",

  data() {
    return {};
  }
};
</script>
<style lang="scss" scoped>
.selection {
  a:link,
  a:visited,
  a:hover,
  a:focus,
  a:active {
    color: $black;
  }
}
</style>
