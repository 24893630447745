<template>
  <div class="auth">
    <div class="app-header row top-bar">
      <div class="col-xs p0">
        <a href="/">
          <img :src="`/assets/img/adready-plus.webp`" class="logo" />
        </a>
      </div>
    </div>
    <div class="container row middle-xs center-xs">
      <div class="auth-form col-xs row">
        <div class="col-xs-7 p0">
          <img :src="`/assets/img/rocket-dark.webp`" class="logo" />
        </div>
        <div class="col-xs">
          <div class="row">
            <label for="email" class="col-xs"
              >Email
              <input type="email" name="email" @input="setEmail" />
            </label>
          </div>
          <div class="row">
            <label for="password" class="col-xs"
              >Password
              <input type="password" name="password" @input="setPassword" />
            </label>
          </div>
          <div class="row">
            <div class="col-xs">
              <v-button
                :outline="false"
                :size="6"
                :event="submitLogin"
                :show-loading="showLoading"
                :disabled="disabled"
                >Login</v-button
              >
            </div>
          </div>
          <div class="row end-xs">
            <div class="col-xs"><a href="/forgot">Forgot Password</a></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      showLoading: false,
      disabled: false,
      user: {
        email: null,
        password: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      isAuthenticated: "authentication/isAuthenticated",
    }),
  },
  watch: {
    isAuthenticated(val) {
      if (val) return this.$router.push("/");
    },
  },
  mounted() {
    if (this.isAuthenticated) {
      this.$router.push("/");
    }
  },
  methods: {
    ...mapActions({
      login: "authentication/login",
    }),
    setEmail($event) {
      this.user.email = $event.target.value;
    },
    setPassword($event) {
      this.user.password = $event.target.value;
    },
    async submitLogin() {
      this.toggleUIElements(true);
      await this.login(this.user);
      this.toggleUIElements(false);
    },
    toggleUIElements(val) {
      this.showLoading = val;
      this.disabled = val;
    },
  },
};
</script>
<style lang="scss" scoped>
.auth .app-header {
  padding-bottom: 20px;

  .logo {
    width: 180px;
    margin: 40px;
  }
}
.auth-form {
  margin-top: -60px;
}
a {
  color: #fff;
}
</style>
