<template>
  <div class="auth">
    <div class="app-header row top-bar">
      <div class="col-xs p0">
        <a href="/">
          <img :src="`/assets/img/logo-small.webp`" class="logo" />
        </a>
      </div>
    </div>
    <div class="container row middle-xs center-xs">
      <div class="auth-form col-xs row">
        <div class="col-xs-7 p0">
          <img :src="`/assets/img/rocket-dark.webp`" class="logo" />
        </div>
        <div class="col-xs">
          <div class="row">
            <label for="email" class="col-xs"
              >Email
              <input type="email" name="email" v-model="email" />
            </label>
          </div>

          <div class="row">
            <div class="col-xs">
              <v-button
                :outline="false"
                :size="6"
                :event="submitResetPassword"
                :show-loading="showLoading"
              >
                Reset Password
              </v-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      email: null,
      showLoading: false
    };
  },
  methods: {
    ...mapActions({ forgotPassword: "authentication/forgotPassword" }),
    async submitResetPassword() {
      this.showLoading = true;

      await this.forgotPassword(this.email);
      this.email = null;
      this.showLoading = false;
    }
  }
};
</script>
