<template>
  <div class="auth">
    <div class="app-header row top-bar">
      <div class="col-xs p0">
        <a href="/">
          <img :src="`/assets/img/logo-small.webp`" class="logo" />
        </a>
      </div>
    </div>
    <div class="container row middle-xs center-xs">
      <div class="auth-form col-xs row">
        <div class="col-xs-7 p0">
          <img :src="`/assets/img/rocket-dark.webp`" class="logo" />
        </div>
        <div class="col-xs">
          <div class="row">
            <label for="password" class="col-xs"
              >Password
              <input type="password" name="password" v-model="reset.password" />
            </label>
          </div>
          <div class="row">
            <label for="email" class="col-xs"
              >Confirm Password
              <input
                type="password"
                name="password"
                v-model="reset.password_confirmation"
              />
            </label>
          </div>

          <div class="row">
            <div class="col-xs">
              <v-button
                :outline="false"
                :size="6"
                :event="submitResetPassword"
                :show-loading="showLoading"
              >
                Reset Password
              </v-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      reset: {
        email: null,
        password: null,
        password_confirmation: null,
        reset_token: null
      },

      showLoading: false
    };
  },
  created() {
    this.reset.email = this.$route.params.email;
    this.reset.reset_token = this.$route.params.token;
  },
  methods: {
    ...mapActions({
      resetPassword: "authentication/resetPassword"
    }),
    async submitResetPassword() {
      if (this.reset.password === this.reset.password_confirmation) {
        this.showLoading = true;
        await this.resetPassword(this.reset);
        this.$router.push({ name: 'login' })
        this.showLoading = false;
      } else {
        //passwords don't match alert
      }
    }
  }
};
</script>
