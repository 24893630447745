<template>
  <div class="container">
    <div class="header row between-xs middle-xs">
      <div class="col-xs-3 reporting-title">Reporting</div>
      <div class="col-xs-2">
        <div class="dropdown">
          <v-select
            @input="didSelectReport"
            :value="selected"
            :clearable="false"
            :options="types"
          ></v-select>
        </div>
      </div>
    </div>
    <div class="iframe-wrapper">
      <iframe
        :src="report.url"
        @load="iframeLoaded"
        id="iframe"
        frameborder="0"
        v-show="loaded"
        :height="report.height"
      ></iframe>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  data() {
    return {
      selected: "Dashboard",
      error: {},
      loaded: false
    };
  },

  computed: {
    ...mapState({
      reporting_urls: state => state.reporting_urls
    }),
    types() {
      return this.reporting_urls.map(obj => obj.type);
    },
    report() {
      let report = this.reporting_urls.filter(
        obj => obj.type === this.selected
      );
      return report.length > 0 ? report[0] : {};
    },
    url() {
      let urls = this.reporting_urls.filter(obj => obj.type === this.selected);
      return urls.length > 0 ? urls[0].url : "";
    }
  },
  async mounted() {
    try {
      await this.getReports();
    } catch (error) {
      this.error = error;
    }

    this.$store.commit("SET_SHOW_LOADING", true);
  },
  methods: {
    ...mapActions({ getReports: "getReports" }),
    iframeLoaded() {
      this.$store.commit("SET_SHOW_LOADING", false);
      this.loaded = true;
    },
    didSelectReport(val) {
      this.selected = val;

      this.$store.commit("SET_SHOW_LOADING", true);
    }
  }
};
</script>

<style lang="scss" scoped>
.header {
  height: 67px;
  .reporting-title {
    font-size: 22px;
    line-height: 1.5em;
  }
}
.iframe-wrapper {
  z-index: 0;

  margin-top: -50px;
}
iframe {
  width: 100%;

  border: 0px;
}
</style>
